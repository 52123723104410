import { GetterTree } from 'vuex'
import { OrdersState, OrderAdministration } from './types'
import { RootState } from '@/store/types'

const getters: GetterTree<OrdersState, RootState> = {
  serializedOrdersAdministration: (state) => {
    const statuses = {
      placed: 'Ждет подтверждения',
      confirmed: 'Подтвержден',
      assembling: 'Собирается',
      assembled: 'Готов к выдаче',
      dispatched: 'В пути',
      completed: 'Завершен',
      canceled: 'Отменен'
    }

    return state.ordersAdministration.map((item: OrderAdministration) => {
      return {
        ...item,
        status: item.status === 'assembling' ? 'placed' : item.status,
        _courier: item?.courier || 'Курьер',
        _client_phone: item?.client_phone.replace(/[ -/+]/g, ''),
        _products: item?.products.map(p => `${p.name} - ${p.qty}`),
        _deliveryMethod: item?.delivery?.method === 'delivery' ? 'Доставка' : 'Самовывоз',
        _paymentMethod: item?.payment?.method_type === 'cash' ? 'Наличные' : 'Картой онлайн',
        _status: statuses[item.status],
        _operator: item?.operator ? state?.orderOperators.find((o: { id: string }) => o.id === item.operator) : null,
        _order_completed_start: item.order_completed_start
      }
    })
  }
}

export default getters
