import { CommonState } from './types'

const state: CommonState = {
  businessCategories: [],
  businessFilters: [],
  breadcrumbItems: [],
  currencyItems: [
    {
      id: 643,
      name: 'RUB'
    },
    {
      id: 417,
      name: 'KGS'
    }
  ],
  loading: false
}

export default state
