import type { MutationTree } from 'vuex'
import type { ChatsState } from './types'

const mutations: MutationTree<ChatsState> = {
  setChatsList (state: any, data) {
    state.chatsList.push(...data)
  },

  setMessages (state: any, { chatId, payload }) {
    state.chatsMessages[chatId] = payload
      .sort((a, b) => (a.created_at < b.created_at) ? -1 : ((a.created_at > b.created_at) ? 1 : 0))
    // replace chat is_viewed value
    const foundChat = state.chatsList.find(c => c.id === chatId)
    foundChat.last_message.is_viewed = true
    const foundChatIdx = state.chatsList.findIndex(c => c.id === chatId)
    state.chatsList[foundChatIdx] = foundChat
  },

  setMessageToCurrentUser (state: any, { chatId, payload }) {
    state.chatsMessages[chatId].push(payload)
  },

  setUnreadChatsCound (state: any, payload) {
    state.unreadChatsCount = payload
  }
}

export default mutations
