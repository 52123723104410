/* eslint-disable camelcase */

import * as apiProfiles from '@/services/api/profiles'
import { ActionTree } from 'vuex'
import { BusinessProfile, ClientProfile, Profile, ProfileRequisites, ProfilesState, ProfileLocation } from './types'
import { RootState } from '@/store/types'

export const actions: ActionTree<ProfilesState, RootState> = {
  async activate ({ getters, dispatch }, payload: [string]) {
    const { data } = await apiProfiles.activate(payload)

    return data
  },

  async getProfiles ({ commit, getters }, payload: boolean) {
    const res = await apiProfiles.getProfiles(payload).then(res => res.data)
    commit('setProfiles', res.items)
    commit('setProfilesCount', res.total)
  },

  async getBisinessProfiles ({ commit, getters }, payload: object) {
    const request = await apiProfiles.getBisinessProfiles(payload).then(res => res)
    if (request.status >= 200 && request.status < 300) {
      commit('setBusinessProfiles', request.data.items)
      commit('setBusinesssProfilesCount', request.data.total)
      return request.data.items
    }
  },

  async getProfile ({ commit, getters }, payload: boolean) {
    const request = await apiProfiles.getProfiles(payload).then(res => res)
    if (request.status >= 200 && request.status < 300) {
      commit('setProfile', request.data)
      return request.data
    }
    return request
  },

  async getBusinessProfile ({ commit }, profileId: string) {
    const request = await apiProfiles.getBusinessProfile(profileId)

    if (request.status >= 200 && request.status < 300) {
      request.data.profile_data.juridical_address = request.data.profile_data?.juridical_address || ''
      request.data.location.address = request?.data.location?.address || ''
      request.data.profile_data.contact_phone = request.data.profile_data.contact_phone ? `+${request.data.profile_data.contact_phone}` : ''
      request.data.phone = request.data.phone ? `+${request.data.phone}` : ''
      commit('setProfile', request.data)
      return request.data
    }
    return request
  },

  async updateClientProfile ({ commit, getters }, payload: ClientProfile) {
    const serializedClientProfile = getters.serializeClientProfile(payload)

    const { data } = await apiProfiles.updateProfile(serializedClientProfile, payload.id)
    data.profile_data.juridical_address = data.profile_data?.juridical_address || ''
    data.location.address = data?.location?.address || ''
    data.profile_data.contact_phone = data.profile_data.contact_phone ? `+${data.profile_data.contact_phone}` : ''
    data.phone = data.phone ? `+${data.phone}` : ''
    commit('setProfile', data)
    return data
  },

  async updateBusinessProfile ({ commit, getters }, payload: BusinessProfile) {
    const serializedBusinessProfile = getters.serializeBusinessProfile(payload)

    const request = await apiProfiles.updateProfile(serializedBusinessProfile, payload.id)
    if (request.status >= 200 && request.status < 300) {
      request.data.profile_data.juridical_address = request.data.profile_data?.juridical_address || ''
      request.data.location.address = request.data?.location?.address || ''
      request.data.profile_data.contact_phone = request.data.profile_data.contact_phone ? `+${request.data.profile_data.contact_phone}` : ''
      request.data.phone = request.data.phone ? `+${request.data.phone}` : ''
      commit('setProfile', request.data)
      return request.data
    }
    return request
  },

  // Удалить у бизнес проифиля список сущностей с информацией о работе бизнеса в конкретный день недели
  async deleteBusinessWorkTimes ({ commit, getters }, payload: { profileId: string, workTimesIds: [] }) {
    const { data } = await apiProfiles.deleteBusinessWorkTimes(payload)
    return data
  },

  async getProfileRequisites ({ commit }, profileId: string) {
    const res = await apiProfiles.getProfileRequisites(profileId).then(res => res.data)
    commit('setProfileRequisites', res)
    return res
  },

  async createProfileRequisites ({ commit }, payload: {profileId: string, requisites: ProfileRequisites}) {
    const res = await apiProfiles.createProfileRequisites(payload).then(res => res)
    if (res.status >= 200 && res.status < 300) {
      commit('setProfileRequisites', res.data)
      return res
    }
    return res
  },

  async updateProfileRequisites ({ commit }, payload: {profileId: string, requisites: ProfileRequisites}) {
    const res = await apiProfiles.updateProfileRequisites(payload).then(res => res)
    if (res.status >= 200 && res.status < 300) {
      commit('setProfileRequisites', res.data)
      return res
    }
    return res
  },

  async updateDeliveryLocation  ({ commit }, payload: { id: string, byField: string, data: ProfileLocation }) {
    const res = await apiProfiles.updateDeliveryLocation(
      {
        id: payload.id,
        byField: payload.byField,
        data: payload.data
      }
    ).then(res => res.data)
    return res
  },

  async getBusinessProfilesForCopyCatalog ({ commit }, payload: {
    limit: number
    offset: number
    search_text?: string
  } = { limit: 100, offset: 0 }) {
    // на данный момент бэк не может пагинировать по 10 штук, отдает сразу всей пачкой, поэтому просим сразу много
    try {
      const { data } = await apiProfiles.getBusinessProfilesForCopyCatalog(payload)
      if (data) {
        commit('setBusinessProfilesForCopyCatalog', data)
        return data
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default actions
