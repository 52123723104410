import { axiosClient as apiClient } from '@/services/api/apiClient'

export const getChatsList = (payload) => apiClient.get('/v1/admin_chats', {
  params: {
    ...payload
  }
})

export const postMessageChatById = (id, payload) => apiClient.post(`/v1/admin_chats/${id}/message`, payload)

export const getChatMessagesById = (id) => apiClient.get(`/v1/chats/${id}/messages`)

export const getUnreadCountChats = () => apiClient.get('/v1/count_unread_chat')
