import { ReportsState, Reports, AvailableDataRage } from './types'

export const initialRequest = {
  id: null
}

const state: ReportsState = {
  reports: [] as Reports[],
  availableDataRage: {} as AvailableDataRage,
  selectedProfiles: [] as string[]
}
export default state
