// Roles
export const ROLE_GUEST = 'Guest'
export const ROLE_USER = 'User'
export const ROLE_ADMIN = 'Admin'

export const GUEST_PAGES = ['auth', 'app-preview', 'app-preview-products', 'app-preview-interstitial']

// Pagination
export const PAGINATION_LIMIT = 25

export const DELIVERY_METHODS = [
  {
    id: 'delivery',
    name: 'Доставка'
  },
  {
    id: 'pickup',
    name: 'Самовывоз'
  }
]

export const GRADIENT_COLORS = [
  {
    from: '#ff8989',
    to: '#fa9c57',
    hex: 'linear-gradient(45deg, #ff8989, #fa9c57 )'
  },
  {
    from: '#ffe279',
    to: '#f15c47',
    hex: 'linear-gradient(45deg, #ffe279, #f15c47 )'
  },
  {
    from: '#b0325e',
    to: '#faa681',
    hex: 'linear-gradient(45deg, #b0325e, #faa681 )'
  },
  {
    from: '#338bc2',
    to: '#dffee6',
    hex: 'linear-gradient(45deg, #338bc2, #dffee6 )'
  },
  {
    from: '#a7eff3',
    to: '#846be6',
    hex: 'linear-gradient(45deg, #a7eff3, #846be6 )'
  },
  {
    from: '#69a5dc',
    to: '#56b665',
    hex: 'linear-gradient(45deg, #69a5dc, #56b665 )'
  },
  {
    from: '#78ce95',
    to: '#dac327',
    hex: 'linear-gradient(45deg, #78ce95, #dac327 )'
  },
  {
    from: '#6964de',
    to: '#fca6e9',
    hex: 'linear-gradient(45deg, #6964de, #fca6e9 )'
  },
  {
    from: '#2a3780',
    to: '#ca61a7',
    hex: 'linear-gradient(45deg, #2a3780, #ca61a7 )'
  },
  {
    from: '#4bb0b6',
    to: '#6518b4',
    hex: 'linear-gradient(45deg, #4bb0b6, #6518b4 )'
  }
]

export const DATE_SORTING = {
  ascending: 'created_at',
  descending: '-created_at'
}

export const STATUSES = {
  processing: 'processing',
  accepted: 'accepted',
  rejected: 'rejected'
}

export const DAYS_OF_WEEK = [
  'Понедельник', 'Вторник', 'Среда', 'Четверг',
  'Пятница', 'Суббота', 'Воскресенье'
]

export const DEVICES_TYPES = {
  android: {
    name: 'android',
    regex: /Android/
  },
  ios: {
    name: 'ios',
    regex: /(iPhone|iPod|iPad)/
  },
  desktop: {
    name: 'desktop',
    regex: ''
  }
}

export const S3_URL = process.env.VUE_APP_S3_URL
