






import Vue from 'vue'
import Settings from './Settings.vue'

export default Vue.extend({
  name: 'AppHeader',
  components: {
    Settings
  }
})
