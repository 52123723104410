import { axiosClient as apiClient } from '@/services/api/apiClient'
import { PAGINATION_LIMIT } from '@/common/constants'

export const getProfilePromocodes = (payload) => apiClient.get(
  '/v1/promo-codes',
  {
    params: {
      business_profile_id: payload.profileId,
      is_archived: payload.isArchived,
      limit: payload.limit || PAGINATION_LIMIT,
      offset: payload.offset || 0
    }
  }
)

export const createPromocode = (payload) => {
  return apiClient.post('/v1/promo-codes', payload)
    .then(data => data)
    .catch(error => error.response)
}

export const archivePromocode = (promocodeId) => apiClient.post(`/v1/promo-codes/${promocodeId}/archive`)
