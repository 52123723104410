import * as apiChats from '@/services/api/chats'

import type { ActionTree } from 'vuex'
import { ChatsState } from './types'
import { RootState } from '@/store/types'

export const actions: ActionTree<ChatsState, RootState> = {
  async getChatsList ({ commit }, payload) {
    try {
      const { data } = await apiChats.getChatsList(payload)
      if (data) {
        commit('setChatsList', data)
        return Promise.resolve(data)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async sendMessageChatById ({ commit }, { id, payload }) {
    try {
      await apiChats.postMessageChatById(id, payload)
      commit('setMessageToCurrentUser', { chatId: id, payload })
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async fetchMessagesChatById ({ commit }, id: string) {
    try {
      const { data } = await apiChats.getChatMessagesById(id)
      if (data) {
        commit('setMessages', { chatId: id, payload: data })
        return Promise.resolve(data)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async fetchUnreadCountChats ({ commit }) {
    try {
      const { data } = await apiChats.getUnreadCountChats()
      if (data) {
        commit('setUnreadChatsCound', data.unread_chats)
        return data
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default actions
