import { axiosClient as apiClient } from '@/services/api/apiClient'

export const getPosts = (payload) => apiClient.get(
  '/v2/posts',
  {
    params: {
      user_id: payload.userId,
      limit: payload.limit ? payload.limit : 25,
      offset: payload.offset
    }
  }
)

export const deletePost = (postId) => apiClient.delete(`/v1/posts/${postId}`)

export const addPost = (payload) => {
  apiClient.post(
    '/v2/posts',
    payload
  )
}
