
import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import router from './router'
import store from './store'
import VueMoment from 'vue-moment'
import moment from 'moment'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import 'bootstrap/scss/bootstrap.scss'

import { ValidationObserver, ValidationProvider, extend, localize, configure } from 'vee-validate'

import VueTippy, { TippyComponent } from 'vue-tippy'

import ru from 'vee-validate/dist/locale/ru.json'
import * as rules from 'vee-validate/dist/rules'

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

import '@/common/globalComponents'
import '@/common/filters'
import App from './App.vue'
import './styles/app.scss'

import VueCurrencyInput from 'vue-currency-input'

import VueCookies from 'vue-cookies'

import { VueMaskDirective } from 'v-mask'

import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

const pluginOptions = {
  globalOptions: {
    currency: 'RUB',
    locale: 'ru'
  }
}
Vue.use(VueTippy)
Vue.component('tippy', TippyComponent)

Vue.use(VueTelInput)

Vue.use(VueCurrencyInput, pluginOptions)

// Vue.use(VueMask)
Vue.directive('mask', function (el, binding) {
  if (!binding.value) return
  VueMaskDirective.bind(el, binding)
})

Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)

Vue.use(VueCookies)
// set default config
Vue.$cookies.config('7d')

// import './plugins/errorHandlers'

Vue.use(BootstrapVue, {
  breakpoints: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']
})
Vue.use(IconsPlugin)
moment.locale('ru')
Vue.use(VueMoment, { moment })
Vue.use(VueToast, {
  position: 'top-right'
})

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})
localize('ru', ru)

const config = {
  // aria: true,
  // classNames: {},
  // classes: false,
  // delay: 0,
  // dictionary: null,
  // errorBagName: 'errors', // change if property conflicts
  // events: 'input|blur',
  // fieldsBagName: 'fields',
  // i18n: null, // the vue-i18n plugin instance
  // i18nRootKey: 'validations', // the nested key under which the validation messages will be located
  // inject: true,
  // locale: 'en',
  // validity: false,
  useConstraintAttrs: false
}
configure(config)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

extend('phone', {
  message: (field: string) => 'Поле ' + field + ' обязательно для заполнения',
  validate: value => {
    return value.replace(/\D/g, '').length === 11
  }
})

extend('url', {
  message: (field: string) => 'Поле ' + field + ' должно быть действительным адресом',
  validate: value => {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(value)
    }
    return false
  }
})

export default new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
