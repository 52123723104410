import * as apiCommon from '@/services/api/common'

import type { ActionTree } from 'vuex'
import { CommonState } from './types'
import { RootState } from '@/store/types'
import { BBreadcrumbItem } from 'bootstrap-vue'

export const actions: ActionTree<CommonState, RootState> = {
  async getBusinessCategories ({ commit }, payload: [string]) {
    const res = await apiCommon.getBusinessCategories(payload).then(res => res.data)
    commit('setBusinessCategories', res)
  },

  async getBusinessFilters ({ commit }) {
    const res = await apiCommon.getBusinessFilters().then(res => res.data)
    commit('setBusinessFilters', res)
  },

  async uploadMedia (context, payload) {
    const res = await apiCommon.uploadMedia(payload).then(res => res.data)
    return res
  },

  setBreadcrumbItems ({ commit }, payload: BBreadcrumbItem[]) {
    commit('setBreadcrumbItems', payload)
  }
}

export default actions
