import { axiosClient as apiClient } from '@/services/api/apiClient'

export const uploadTinkoffReport = payload =>
  apiClient
    .post('/v1/tinkoff-report', payload)
    .then(data => data)
    .catch(error => error.response)

export const availableDataRange = (payload) =>
  apiClient
    .get(`/v1/order-transactions/available-dates-range${payload || ''}`)
    .then(data => data)
    .catch(error => error.response)

export const uploadMassReport = (payload) =>
  apiClient
    .post('/v1/mass-report', payload)
    .then(data => data)
    .catch(error => error.response)

export const uploadMassReportByEmail = (payload) =>
  apiClient
    .post('/v1/mass-report/email', payload)
    .then(data => data)
    .catch(error => error.response)
