import * as apiPosts from '@/services/api/posts'

export default {
  async getPosts ({ commit, getters }, payload) {
    const res = await apiPosts.getPosts(payload).then(res => res.data)
    commit('setPosts', res)
    return res
  },

  async deletePost ({ commit, getters }, promocodeId: string) {
    const res = await apiPosts.deletePost(promocodeId)
    // commit('deletePost', res.id)
  },

  async addPost ({ commit, getters }, payload) {
    const res = await apiPosts.addPost(payload)
  }
}
