import * as authService from '@/services/auth'

export default {
  setPosts (state, payload) {
    state.posts = payload
  },

  resetPosts (state) {
    state.posts = []
  }
}
