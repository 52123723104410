import { MutationTree } from 'vuex'
import { Promocode, PromocodesState } from './types'

const mutations: MutationTree<PromocodesState> = {
  setPromocodes (state: any, data: Promocode[]) {
    state.promocodes = data
  }
}

export default mutations
