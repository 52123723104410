import { GetterTree } from 'vuex'
import { RequestState, Request, RequestUpdateBody } from './types'
import { RootState } from '@/store/types'

const getters: GetterTree<RequestState, RootState> = {

  serializeRequest: () => (data: Request): Request => ({
    avatar_url: data.avatar_url,
    category: data.category,
    contact_phone: `+${data.contact_phone}`,
    created_at: data.created_at,
    description: data.description,
    id: data.id,
    juridical_address: data.juridical_address,
    location: data.location,
    name: data.name,
    status: data.status,
    updated_at: data.updated_at,
    user_id: data.user_id,
    username: data.username,
    website: data.website
  }),

  serializeUpdateRequest: () => (data: Request): RequestUpdateBody => ({
    avatar_url: data.avatar_url,
    category_id: data.category.id,
    contact_phone: data.contact_phone,
    description: data.description,
    juridical_address: data.juridical_address,
    location: data.location,
    name: data.name,
    username: data.username,
    website: data.website
  })
}

export default getters
