import Vue from 'vue'
import { MutationTree } from 'vuex'
import { Catalog, CatalogCategory, CatalogChildCategory, Product, ProductsState } from './types'

const mutations: MutationTree<ProductsState> = {
  setCatalog (state: any, data: Catalog) {
    state.catalog = data
  },

  setCatalogsForCopy (state, data) {
    state.catalogsForCopy = data
  },

  setCatalogContent (state: any, data: Catalog) {
    state.catalogContent = data
  },

  setCatalogCategories (state: any, data: CatalogCategory[]) {
    state.catalogCategories = data
  },

  setCategoriesHierarchy (state: any, data) {
    state.categoriesHierarchy = data
  },

  updateCatalogCategory (state: any, category: CatalogCategory) {
    const categoryIndex = state.catalogCategories.findIndex((item: CatalogCategory) => item.id === category.id)
    Vue.set(state.catalogCategories, categoryIndex, category)
  },

  setCatalogChildCategories (state: any, data: CatalogChildCategory[]) {
    state.catalogChildCategories = data
  },

  updateCatalogChildCategory (state: any, payload: { categoryId: string, data: CatalogChildCategory }) {
    const index = state.catalogChildCategories.findIndex((item: CatalogChildCategory) => item.id === payload.data.id)
    Vue.set(state.catalogChildCategories, index, payload.data)
  },

  setCatalogs (state: any, data: Catalog[]) {
    state.catalogs = data
  },

  setCatalogChildCategoryProducts (state: any, payload: Product[]) {
    state.catalogChildCategoryProducts = payload
  },

  addCatalog (state: any, payload: Catalog) {
    state.catalogs.push(payload)
  },

  removeCatalog (state: any, catalogId: string) {
    const catalogIndex = state.catalogs.findIndex((item: Catalog) => item.id === catalogId)
    state.catalogs.splice(catalogIndex, 1)
  },

  removeCatalogCategory (state: any, categoryId: string) {
    const categoryIndex = state.catalogCategories.findIndex((item: CatalogCategory) => item.id === categoryId)
    state.catalogCategories.splice(categoryIndex, 1)
  },

  removeCatalogChildCategory (state: any, categoryId: string) {
    const index = state.catalogChildCategories.findIndex((item: CatalogChildCategory) => item.id === categoryId)
    state.catalogChildCategories.splice(index, 1)
  },

  addCatalogCategory (state: any, payload: CatalogCategory) {
    state.catalogCategories.push(payload)
  },

  addCatalogChildCategory (state: any, payload: { categoryId: string, childCategory: CatalogChildCategory }) {
    if (state.selectedCatalogCategory.id !== payload.categoryId) return
    state.catalogChildCategories.push(payload.childCategory)
  },

  addProduct (state: any, payload: Product) {
    state.catalogChildCategoryProducts.push(payload)
  },

  updateProduct (state: any, product: Product) {
    const productIndex = state.catalogChildCategoryProducts.findIndex((item: Product) => item.id === product.id)

    if (product.category_id !== state.selectedCatalogChildCategory.id) {
      state.catalogChildCategoryProducts.splice(productIndex, 1)
      return
    }

    Vue.set(state.catalogChildCategoryProducts, productIndex, product)
  },

  updateCatalog (state: any, catalog: Catalog) {
    const catalogIndex = state.catalogs.findIndex((item: Catalog) => item.id === catalog.id)

    Vue.set(state.catalogs, catalogIndex, catalog)

    if (!catalog.is_hidden) {
      state.catalogs.map((catalog: Catalog, index: number) => {
        if (index === catalogIndex) return catalog
        catalog.is_hidden = true
        return catalog
      })
    }
  },

  sortProducts (state: any, payload) {
    state.catalogChildCategoryProducts = payload.newList
  },

  sortCategories (state: any, payload) {
    state.catalogCategories = payload.newList
    // const sortedItem = payload.newList.find(item => item.id === payload.categoryInsertId)
    // const sortedItemIndex = state.catalogCategories.findIndex(item => item.id === sortedItem.id)
    // let newIndex = 0

    // if (payload.categoryAfterInsertId) {
    //   newIndex = payload.newList.findIndex(item => item.id === payload.categoryAfterInsertId) + 1
    // }

    // state.catalogCategories.splice(sortedItemIndex, 1)
    // state.catalogCategories.splice(newIndex, 0, sortedItem)
  },

  sortChildCategories (state: any, payload) {
    state.catalogChildCategories = payload.newList
  },

  resetCatalog (state) {
    state.catalog = {} as Catalog
  },

  deleteProduct (state: any, productId) {
    // Находим категорию, где находится продукт
    const categoryIndex = state.catalog.categories.findIndex((item: { id: any }) => item.id === state.currentCategoryTab.id)
    const products = state.catalog.categories[categoryIndex].products
    const productIndex = products.findIndex((item: { id: any }) => item.id === productId)

    products.splice(productIndex, 1)

    if (!products.length) {
      state.catalog.categories.splice(categoryIndex, 1)
      if (state.catalog.categories.length) {
        state.currentCategoryTab = {
          id: state.catalog.categories[0].id,
          name: state.catalog.categories[0].name
        }
      } else {
        state.currentCategoryTab = { id: null, name: null }
      }
      return
    }

    Vue.set(state.catalog.categories[categoryIndex], 'products', products)
  },

  setCurrentCategoryTab (state, data) {
    state.currentCategoryTab = data
  },

  setSelectedCatalogCategory (state, payload: CatalogCategory) {
    state.selectedCatalogCategory = payload
  },

  setSelectedCatalogChildCategory (state, payload: CatalogChildCategory) {
    state.selectedCatalogChildCategory = payload
  },

  setEditingCatalogCategory (state, payload: CatalogCategory) {
    state.editingCatalogCategory = payload
  },

  setEditingCatalogChildCategory (state, payload: CatalogChildCategory) {
    state.editingCatalogChildCategory = payload
  }
}

export default mutations
