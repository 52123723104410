import { cloneDeep } from 'lodash'
import initialProfile from './state'
import { MutationTree } from 'vuex'
import { ProfileRequisites, ProfilesState } from './types'

const mutations: MutationTree<ProfilesState> = {
  setProfiles (state, payload) {
    state.profiles = payload
  },

  setBusinessProfiles (state, payload) {
    state.businesssProfiles = payload
  },
  setBusinesssProfilesCount (state, payload) {
    state.businesssProfilesCount = payload
  },

  setProfilesCount (state, payload) {
    state.profilesCount = payload
  },

  setProfile (state, payload) {
    if (payload.profile_type === 'business') {
      payload.profile_data.work_time_schedule = payload.profile_data.work_time_schedule.map(item => {
        item.works_from = item.works_from.slice(0, -3)
        item.works_until = item.works_until.slice(0, -3)
        item.isValid = true
        return item
      })
    }

    state.profile = payload
  },

  resetProfile (state: any) {
    state.profile = cloneDeep(initialProfile)
  },

  setProfileRequisites (state, payload: ProfileRequisites) {
    state.profileRequisites = payload
  },

  setBusinessProfilesForCopyCatalog (state, payload) {
    state.businessProfilesForCopyCatalog = payload.items
    state.businessProfilesForCopyCatalogCount = payload.total
  }
}

export default mutations
