import { cloneDeep, isEqual } from 'lodash'

export default {
  updateUser (state, payload) {
    const newUser = {
      ...state.user,
      ...payload
    }

    if (isEqual(state, newUser)) return

    state.user = newUser
    // persistedStorage.updateUser(newUser)
    // EventBus.emit('user:update', Object.freeze(cloneDeep(newUser)))
  }
}
