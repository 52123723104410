import { GetterTree } from 'vuex'
import { ProfilesState, Profile, ProfileRequisites, SerializedProfile, SerializedBusinessProfile, SerializedClientProfile, ClientProfile, BusinessProfile } from './types'
import { RootState } from '@/store/types'

const getters: GetterTree<ProfilesState, RootState> = {
  getNewRequisites: () => (): ProfileRequisites => ({
    company_name: '',
    full_company_name: '',
    city: '',
    street: '',
    zip: '',
    ceo_first_name: '',
    ceo_last_name: '',
    ceo_middle_name: '',
    ceo_birthdate: '',
    ceo_phone: '',
    inn: '',
    kpp: '',
    ogrn: '',
    checking_account: '',
    correspondent_account: '',
    bank_name: '',
    bik: '',
    phone: '',
    email: '',
    site_url: ''
  }),

  serializeBusinessProfile: () => (data: BusinessProfile): SerializedBusinessProfile => ({
    avatar_url: data.avatar_url,
    email: data.email,
    name: data.name,
    phone: data.phone,
    username: data.username,
    location: data.location,
    profile_type: data.profile_type,
    profile_data: {
      category_id: data.profile_data.category.id,
      contact_phone: data.profile_data.contact_phone,
      description: data.profile_data.description,
      is_hidden: data.profile_data.is_hidden,
      is_verified: data.profile_data.is_verified,
      website: data.profile_data.website,
      rating: data.profile_data.rating,
      average_check: data.profile_data.average_check,
      commission_percentage: data.profile_data.commission_percentage,
      bg_image: data.profile_data.bg_image,
      food_type: data.profile_data.food_type,
      delivery_time: data.profile_data.delivery_time,
      currency: data.profile_data.currency,
      payment_methods: data.profile_data.payment_methods,
      juridical_address: data.profile_data.juridical_address,
      work_schedule: data.profile_data.work_schedule,
      work_time_schedule: data.profile_data.work_time_schedule.filter(item => item.works_from && item.works_until)
    }
  }),

  serializeClientProfile: () => (data: ClientProfile): SerializedClientProfile => ({
    avatar_url: data.avatar_url,
    email: data.email,
    name: data.name,
    phone: data.phone,
    username: data.username,
    profile_type: data.profile_type,
    profile_data: {
      birthdate: data.profile_data.birthdate,
      gender: data.profile_data.gender
    }
  })
}

export default getters
