/* eslint-disable camelcase */

import * as apiReports from '@/services/api/reports'
import { ActionTree } from 'vuex'
import { ReportsState, Reports } from './types'
import { RootState } from '@/store/types'

export const actions: ActionTree<ReportsState, RootState> = {

  async getAvailableDataRange ({ commit, getters }, payload: object) {
    const request = await apiReports.availableDataRange(payload)
    if (request.status >= 200 && request.status < 300) {
      const serializedData = getters.serializeAvailableDataRange(request.data)
      commit('setAvailableDataRange', serializedData)
      return serializedData
    }
    return request
  },

  async uploadMassReport  ({ commit, getters }, payload: {
    from_date: string,
    to_date: string,
    business_profile_ids: []
  }) {
    const request = await apiReports.uploadMassReport(payload)
    return request
  },

  async uploadMassReportByEmail  ({ commit, getters }, payload: {
    subject: string,
    body: string,
    recipient: string,
    from_date: string,
    to_date: string,
    business_profile_ids: []
  }) {
    const request = await apiReports.uploadMassReportByEmail(payload)
    return request
  }
}

export default actions
