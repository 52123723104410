






























import Vue from 'vue'
import UploadTinkoffModal from './Report/UploadTinkoffModal.vue'

export default Vue.extend({
  name: 'Settings',

  components: {
    UploadTinkoffModal
  },

  data () {
    return {
      isHovered: false,
      modalShow: false
    }
  },
  methods: {
    uploadReport () {
      this.modalShow = true
    },
    onFileUploaded () {
      this.modalShow = false
    }
  }
})
