












import Vue from 'vue'
import {
  mapState
} from 'vuex'
import LayoutMain from '@/layouts/LayoutMain.vue'
import LayoutAuth from '@/layouts/LayoutAuth.vue'
import Loading from '@/components/Loading/index.vue'
import { GUEST_PAGES } from '@/common/constants'

export default Vue.extend({
  name: 'App',

  components: {
    LayoutMain,
    LayoutAuth,
    Loading
  },

  computed: {
    ...mapState('common', [
      'loading'
    ]),

    layoutName () {
      return GUEST_PAGES.includes(this.$route.name) ? 'layout-auth' : 'layout-main'
    }
  },

  created () {
    this.injectBslToConsole()
  },

  methods: {
    injectBslToConsole () {
      // eslint-disable-next-line no-console
      console.log('%c Приходи работать в BSL! ', 'background: #000; color: #ff7303; font-size: 30px;')
      // eslint-disable-next-line no-console
      console.log('%c Ждем тебя мы https://hh.ru/employer/3879484', 'color: #ff7303;')
    }
  }
})
