import { cloneDeep } from 'lodash'
import initialRequest from './state'
import { MutationTree } from 'vuex'
import { RequestState } from './types'

const mutations: MutationTree<RequestState> = {
  setRequests (state, payload) {
    state.requests = payload
  },

  setRequestCount (state, payload) {
    state.requestCount = payload
  },

  setRequest (state, payload) {
    state.request = payload
  },

  resetRequest (state: any) {
    state.request = cloneDeep(initialRequest)
  }
}

export default mutations
