import * as apiOrders from '@/services/api/orders'

export default {
  async getOrderTransactions ({ commit }, payload: {
    from: string,
    to: string,
    profileId: number,
    limit: number,
    offset: number,
  }) {
    return await apiOrders.getOrderTransactions(payload).then(res => res.data)
    // commit('setOrderTransactions', res)
    // return res
  },

  async getReports ({ commit }, payload: {
    from: string,
    to: string,
    profileId: number,
    limit: number,
    offset: number,
  }) {
    return await apiOrders.getReports(payload).then(res => res.data)
    // commit('setOrderTransactions', res)
    // return res
  },

  async createReport ({ commit }, payload: {
    from: string,
    to: string,
    businessProfileId: string
  }) {
    return await apiOrders.createReport(payload).then(res => res.data)
    // commit('setOrderTransactions', res)
    // return res
  },

  async sendReportToEmail ({ commit }, payload: {
    subject: string,
    body: string,
    recipientEmail: string
  }) {
    return await apiOrders.sendReportToEmail(payload).then(res => res.data)
    // commit('setOrderTransactions', res)
    // return res
  },

  async getOrdersAdministration ({ commit }, payload) {
    try {
      const { data } = await apiOrders.getOrdersAdministration(payload)
      if (data) {
        commit('setOrdersAdministration', data)
        return Promise.resolve(data)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async editOrderAdministration ({ commit }, { payload, id }) {
    try {
      await apiOrders.editOrderAdministration({ id, payload })
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async getOrderOperators ({ commit }) {
    try {
      const { data } = await apiOrders.getOrderOperators()
      if (data) {
        commit('setOrderOperators', data)
        return Promise.resolve(data)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async createOrdersReport (_, payload) {
    try {
      const { data } = await apiOrders.createOrdersReport(payload)
      return Promise.resolve(data?.xlsx_document_url)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
