import * as apiAreas from '@/services/api/areas'
import { Area } from './types'

function prepareCoords (area: Area) {
  return area.shapes[0].geometry.map(item => {
    return {
      latitude: item[0],
      longitude: item[1]
    }
  })
}

export default {
  async getAreas ({ commit }) {
    const res = await apiAreas.getAreas().then(res => res.data)
    commit('setAreas', res)
    return res
  },

  async getBusinessProfiles ({ commit }, areaId: string = null) {
    const res = await apiAreas.getBusinessProfiles(areaId).then(res => res.data)
    return res
  },

  async createArea ({ commit, getters }, area: Area) {
    area.shapes[0].geometry = prepareCoords(area)
    const res = await apiAreas.createArea(area)

    if (res.status === 200) {
      commit('addArea', res.data)
    }
    return res.data
  },

  async updateArea ({ commit, getters, state }, area: Area) {
    area.shapes[0].geometry = prepareCoords(area)
    const res = await apiAreas.updateArea(area).then(res => res.data)
    commit('updateArea', res)
    return res
  },

  async deleteArea ({ commit, getters }, areaId: string) {
    const res = await apiAreas.deleteArea(areaId).then(res => res.data)
    commit('deleteArea', areaId)
    return res
  }
}
