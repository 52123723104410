/* eslint-disable camelcase */

import * as apiRequests from '@/services/api/requests'
import { ActionTree } from 'vuex'
import { RequestState, Request } from './types'
import { RootState } from '@/store/types'

export const actions: ActionTree<RequestState, RootState> = {
  async activate ({ getters, dispatch }, payload: [string]) {
    const { data } = await apiRequests.activate(payload)

    return data
  },

  async getRequests ({ commit, getters }, payload: boolean) {
    const request = await apiRequests.getRequests(payload).then(res => res)
    if (request.status >= 200 && request.status < 300) {
      commit('setRequests', request.data.items)
      commit('setRequestCount', request.data.total)
      return request.data
    }
    return request
  },

  async getRequest ({ commit, getters }, requestId: string) {
    const request = await apiRequests.getRequest(requestId)
    const result = getters.serializeRequest(request.data)
    commit('setRequest', result)
  },

  async updateRequest ({ commit, getters }, payload: Request) {
    const serializedRequest = getters.serializeUpdateRequest(payload)

    const request = await apiRequests.updateRequest(payload.id, serializedRequest)
    if (request.status >= 200 && request.status < 300) {
      const requestData = getters.serializeRequest(request.data)
      commit('setRequest', requestData)
      return request.data
    }
    return request
  },

  async updateRequestStatus ({ commit, getters }, payload) {
    const request = await apiRequests.updateRequestStatus(payload.requestId, payload.status)
    if (request.status >= 200 && request.status < 300) {
      const requestData = getters.serializeRequest(request.data)
      commit('setRequest', requestData)
      return request.data
    }
    return request
  }
}

export default actions
