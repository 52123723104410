import * as apiPromocodes from '@/services/api/promocodes'
import { Promocode } from './types'

export default {
  async getProfilePromocodes ({ commit }, payload: {
    profileId: string,
    isArchived: boolean,
    limit: number,
    offset: number,
  }) {
    const res = await apiPromocodes.getProfilePromocodes(payload).then(res => res.data)
    commit('setPromocodes', res)
    return res
  },

  async createPromocode ({ commit, getters }, promocode: Promocode) {
    promocode.code = promocode.code.toUpperCase()
    // const serializedPromocode = getters.serializePromocode(promocode)
    const request = await apiPromocodes.createPromocode(promocode)
    return request
  },

  async archivePromocode ({ commit, getters }, promocodeId: string) {
    const res = await apiPromocodes.archivePromocode(promocodeId)
    return res
  }
}
