import { ProfilesState, Profile, ProfileRequisites } from './types'

export const initialProfile = {
  id: null
}

const state: ProfilesState = {
  profiles: [],
  businesssProfiles: [],
  businesssProfilesCount: 0 as number,
  profile: {} as Profile,
  profileRequisites: {} as ProfileRequisites,
  profilesCount: 0 as number,
  businessProfilesForCopyCatalog: [],
  businessProfilesForCopyCatalogCount: 0 as number
}

export default state
