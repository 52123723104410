import { BBreadcrumbItem } from 'bootstrap-vue'
import type { MutationTree } from 'vuex'
import type { CommonState } from './types'

const mutations: MutationTree<CommonState> = {
  setBusinessCategories (state, payload) {
    state.businessCategories = payload
  },

  setBusinessFilters (state, payload) {
    state.businessFilters = payload
  },

  setLoading (state, payload) {
    state.loading = payload
  },

  setBreadcrumbItems (state, payload: BBreadcrumbItem[]) {
    state.breadcrumbItems = payload
  }
}

export default mutations
