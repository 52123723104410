












import Vue from 'vue'
import AppHeader from '@/components/AppHeader.vue'
import AppFooter from '@/components/AppFooter.vue'
import Sidebar from '@/components/Sidebar.vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'App',

  components: {
    AppHeader,
    AppFooter,
    Sidebar
  },

  data () {
    return {
      intervalUnreadCount: null
    }
  },

  computed: {
    routeClass () {
      return `page-${this.$route.name}`
    }
  },

  methods: {
    ...mapActions({
      fetchUnreadCountChats: 'chats/fetchUnreadCountChats',
      getChatsList: 'chats/getChatsList'
    }),

    fetchChatsData () {
      this.fetchUnreadCountChats()
      this.getChatsList({
        limit: 20,
        offset: 0
      })
    }
  },

  mounted () {
    this.fetchChatsData()

    // this.intervalUnreadCount = setInterval(() => {
    // if (!document.hidden) {
    // this.fetchChatsData()
    // }
    // }, 60000)

    // addEventListener('focus', () => this.fetchChatsData())
  },

  beforeDestroy () {
    this.intervalUnreadCount = null
  }
})
