import * as apiAuth from '@/services/api/auth'
// import JwtService from '@/common/jwt.service'
import * as authService from '@/services/auth'
import store from '@/store'
import { ROLE_GUEST } from '@/common/constants'

export default {
  async login ({ getters, dispatch }, { email, password }) {
    const { data } = await apiAuth.login({ email, password })

    authService.setAccess(data)
    // await store.dispatch('initUser', data.user)

    return data
  },
  async startAnonymousSession ({ getters, dispatch }) {
    const request = await apiAuth.anonymousSession()
    request.data.role = ROLE_GUEST
    authService.setAccess(request.data)

    return request.data
  }
}
