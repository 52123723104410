import Vue from 'vue'
import VueRouter from 'vue-router'
import multiguard from 'vue-router-multiguard'
import authGuard from '@/router/guards/auth-guard'
import {
  ROLE_GUEST,
  ROLE_ADMIN
} from '@/common/constants'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/profiles/requests',
    name: 'home',
    meta: {
      title: 'Главная',
      roles: [
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home/Home.vue')
  },
  {
    path: '/profiles',
    redirect: '/profiles/requests',
    name: 'profiles',
    meta: {
      title: 'Бизнес профили',
      roles: [
        ROLE_ADMIN
      ]
    }
  },
  {
    path: '/profiles/requests',
    name: 'profiles-requests',
    meta: {
      title: 'Запросы на бизнес профиль',
      roles: [
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "requests" */ '@/views/Requests/Requests.vue')
  },
  {
    path: '/profiles/requests/:requestId',
    name: 'request-detail',
    meta: {
      title: 'Запрос на бизнес профиль',
      roles: [
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "requestDetail" */ '@/views/RequestDetail/RequestDetail.vue')
  },
  {
    path: '/profiles/active',
    name: 'profiles-active',
    meta: {
      title: 'Активные бизнес профили',
      roles: [
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "profiles-active" */ '@/views/Profiles/BusinessProfiles.vue')
  },
  {
    path: '/profiles/clients',
    name: 'profiles-clients',
    meta: {
      title: 'Обычные профили',
      roles: [
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "profiles-active" */ '@/views/Profiles/ClientProfiles.vue')
  },
  {
    path: '/profiles/:profileId',
    name: 'profile-detail',
    redirect: '/profiles/:profileId/main',
    meta: {
      title: 'Профиль',
      roles: [
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "profile-detail" */ '@/views/ProfileDetail/ProfileDetail.vue'),
    children: [
      {
        path: '/profiles/:profileId/main',
        name: 'profile-main',
        meta: {
          title: 'Профиль',
          roles: [
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "profile-main" */ '@/views/ProfileDetail/components/Profile.vue')
      },
      {
        path: '/profiles/:profileId/requisites',
        name: 'profile-requisites',
        meta: {
          title: 'Реквизиты',
          roles: [
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "profile-requisites" */ '@/views/ProfileDetail/components/Requisites.vue')
      },
      {
        path: '/profiles/:profileId/posts',
        name: 'profile-posts',
        meta: {
          title: 'Лента',
          roles: [
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "profile-posts" */ '@/views/ProfileDetail/components/Posts.vue')
      },

      {
        path: '/profiles/:profileId/catalogs',
        name: 'profile-catalogs',
        meta: {
          title: 'Каталоги',
          roles: [
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "profile-catalogs" */ '@/views/ProfileDetail/components/Catalogs.vue')
      },

      {
        path: '/profiles/:profileId/catalogs/:catalogId',
        name: 'profile-catalog',
        meta: {
          title: 'Каталог',
          roles: [
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "profile-catalog" */ '@/views/ProfileDetail/components/Catalog.vue')
      },

      {
        path: '/profiles/:profileId/report',
        name: 'profile-report',
        meta: {
          title: 'Отчёт',
          roles: [
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "profile-report" */ '@/views/ProfileDetail/components/Report/index.vue')
      },
      {
        path: '/profiles/:profileId/promocodes',
        // redirect: '/profiles/:id/promocodes/current',
        name: 'profile-promocodes',
        meta: {
          title: 'Промокоды',
          roles: [
            ROLE_ADMIN
          ]
        },
        // children: [
        //   {
        //     path: '/profiles/:id/promocodes/current',
        //     name: 'profile-current-promocodes',
        //     meta: {
        //       title: 'Текущие промокоды',
        //       roles: [
        //         ROLE_ADMIN
        //       ]
        //     },
        //     component: () => import(/* webpackChunkName: "profile-current-promocodes" */ '@/views/ProfileDetail/components/Promocodes.vue')
        //   },
        //   {
        //     path: '/profiles/:id/promocodes/archived',
        //     name: 'profile-archived-promocodes',
        //     meta: {
        //       title: 'Архивные промокоды',
        //       roles: [
        //         ROLE_ADMIN
        //       ]
        //     },
        //     component: () => import(/* webpackChunkName: "profile-archived-promocodes" */ '@/views/ProfileDetail/components/Promocodes.vue')
        //   }
        // ],
        component: () => import(/* webpackChunkName: "profile-promocodes" */ '@/views/ProfileDetail/components/Promocodes.vue')
      },
      {
        path: '/profiles/:profileId/statistics',
        name: 'profile-statistics',
        meta: {
          title: 'Статистика',
          roles: [
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "profile-posts" */ '@/views/ProfileDetail/components/Statistics.vue')
      }
    ]
  },
  {
    path: '/auth',
    name: 'auth',
    meta: {
      title: 'Авторизация',
      roles: [
        ROLE_GUEST,
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth/Auth.vue')
  },

  {
    path: '/areas',
    name: 'areas',
    meta: {
      title: 'Зоны',
      roles: [
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "areas" */ '@/views/Areas/Areas.vue')
  },

  {
    path: '/promocodes',
    name: 'promocodes',
    meta: {
      title: 'Промокоды',
      roles: [
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "perimetr-promocodes" */ '@/views/Promocodes/index.vue')
  },

  {
    path: '/orders',
    name: 'orders',
    meta: {
      title: 'Заказы',
      roles: [
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "perimetr-promocodes" */ '@/views/Orders/index.vue')
  },

  {
    path: '/long-delivery',
    name: 'long-delivery',
    meta: {
      title: 'Дальние доставки',
      roles: [
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "perimetr-promocodes" */ '@/views/LongDelivery/index.vue')
  },

  {
    path: '/chats',
    name: 'chats',
    meta: {
      title: 'Чаты',
      roles: [
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "perimetr-promocodes" */ '@/views/Chats/index.vue')
  },

  {
    path: '/pgt',
    name: 'pgt',
    meta: {
      title: 'Профили генераторы трафика',
      roles: [
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "pgt" */ '@/views/PGT/index.vue')
  },

  {
    path: '/pgt/:profileId',
    name: 'pgt-detail',
    redirect: '/pgt/:profileId/main',
    meta: {
      title: 'Профили генераторы трафика',
      roles: [
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "pgt-detail" */ '@/views/PGTProfile/index.vue'),
    children: [
      {
        path: '/pgt/:profileId/main',
        name: 'pgt-main',
        meta: {
          title: 'Профили генераторы трафика',
          roles: [
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "pgt-main" */ '@/views/PGTProfile/components/Profile.vue')
      },
      {
        path: '/pgt/:profileId/requisites',
        name: 'pgt-requisites',
        meta: {
          title: 'Реквизиты',
          roles: [
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "pgt-requisites" */ '@/views/PGTProfile/components/Requisites.vue')
      },
      {
        path: '/pgt/:profileId/posts',
        name: 'pgt-posts',
        meta: {
          title: 'Лента',
          roles: [
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "pgt-posts" */ '@/views/ProfileDetail/components/Posts.vue')
      },

      {
        path: '/pgt/:profileId/catalogs',
        name: 'pgt-catalogs',
        meta: {
          title: 'Каталоги',
          roles: [
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "pgt-catalogs" */ '@/views/PGTProfile/components/Catalogs.vue')
      },

      {
        path: '/pgt/:profileId/catalogs/:catalogId',
        name: 'pgt-catalog',
        meta: {
          title: 'Каталог',
          roles: [
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "pgt-catalog" */ '@/views/PGTProfile/components/Catalog.vue')
      },

      {
        path: '/pgt/:profileId/report',
        name: 'pgt-report',
        meta: {
          title: 'Отчёт',
          roles: [
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "pgt-report" */ '@/views/PGTProfile/components/Report.vue')
      },
      {
        path: '/pgt/:profileId/promocodes',
        // redirect: '/profiles/:id/promocodes/current',
        name: 'pgt-promocodes',
        meta: {
          title: 'Промокоды',
          roles: [
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "pgt-promocodes" */ '@/views/PGTProfile/components/Promocodes.vue')
      },
      {
        path: '/pgt/:profileId/statistics',
        name: 'pgt-statistics',
        meta: {
          title: 'Статистика',
          roles: [
            ROLE_ADMIN
          ]
        },
        component: () => import(/* webpackChunkName: "pgt-posts" */ '@/views/PGTProfile/components/Statistics.vue')
      }
    ]
  },
  {
    path: '/app-preview',
    name: 'app-preview-interstitial',
    meta: {
      roles: [
        ROLE_GUEST,
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "app-preview-interstitial" */ '@/views/AppPreview/index.vue')
  },
  {
    path: '/app-preview/:profileId',
    name: 'app-preview',
    meta: {
      roles: [
        ROLE_GUEST,
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "app-preview" */ '@/views/AppPreview/_id/index.vue')
  },
  {
    path: '/app-preview/:profileId/catalog/:catalogId',
    name: 'app-preview-products',
    meta: {
      roles: [
        ROLE_GUEST,
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "app-preview" */ '@/views/AppPreview/_id/Catalog/index.vue')
  },
  {
    path: '/reports',
    name: 'reports',
    meta: {
      title: 'Отчет БП по наличию транзакций за период',
      roles: [
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "reports" */ '@/views/Reports/index.vue')
  },

  {
    path: '*',
    name: 'page-not-found',
    meta: {
      roles: [
        ROLE_GUEST,
        ROLE_ADMIN
      ]
    },
    component: () => import(/* webpackChunkName: "page-not-found" */ '@/views/PageNotFound/index.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

// TODO: временно отключили проверку авторизации
router.beforeEach(multiguard([
  authGuard
]))

export default router
