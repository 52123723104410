import { axiosClient as apiClient } from '@/services/api/apiClient'
import { DATE_SORTING } from '@/common/constants'

export const activate = payload =>
  apiClient.post('/v1/allowed-businesses', payload)

const generateParams = payload => {
  const params = {
    page: payload.page || 1,
    size: payload.size || 25,
    order_by: payload.order_by || DATE_SORTING.descending,
    status: 'processing'
  }
  if (payload.isAllowedForBusiness) {
    params.isAllowedForBusiness = payload.isAllowedForBusiness
  }
  if (payload.filter_value) {
    params.search = payload.filter_value
  }
  return { ...params }
}

export const getRequests = payload => {
  const url = payload.field
    ? `/v1/business-requests/${payload.field}`
    : '/v1/business-requests'
  const params = generateParams(payload)

  if (!payload.field) delete params.by_field
  return apiClient
    .get(url, { params })
    .then(data => data)
    .catch(error => error.response)
}

export const getRequest = requestId =>
  apiClient.get(`/v1/business-requests/${requestId}`)

export const updateRequest = (requestId, request) => {
  return apiClient.patch(`/v1/business-requests/${requestId}`, request)
    .then(data => data)
    .catch(error => error.response)
}

export const updateRequestStatus = (requestId, payload) => {
  return apiClient
    .patch(`/v1/business-requests/${requestId}/status`, payload)
    .then(data => data)
    .catch(error => error.response)
}
