import { PgtState, Profile, UpdateProfileBody } from './types'

export const initialPgtProfile = {
  id: null
}

const state: PgtState = {
  pgtProfiles: [],
  pgtProfile: {} as Profile,
  pgtProfilesCount: 0 as number,
  partners: {} as UpdateProfileBody[]
}

export default state
