import { axiosClient as apiClient } from '@/services/api/apiClient'
import { DATE_SORTING } from '@/common/constants'

const PGT_PROFILE_ADDRESS = '/v1/pgt-profiles'

export const createPGT = payload => {
  return apiClient.post(
    `/v1/users/${payload.profileId}/pgt-profile`,
    payload.data
  )
}

const generateParams = payload => {
  const params = {
    // page: payload.page || 1,
    // size: payload.size || 25,
    offset: payload.offset || 0,
    limit: payload.limit || 25,
    order_by: payload.order_by || DATE_SORTING.descending,
    status: 'processing',
    latitude: 0.0,
    longitude: 0.0
  }
  if (payload.filter_value) {
    params.search = payload.filter_value
  }
  return { ...params }
}

export const getPgtProfiles = payload => {
  const url = payload.field
    ? `${PGT_PROFILE_ADDRESS}/${payload.field}`
    : PGT_PROFILE_ADDRESS
  const params = generateParams(payload)

  if (!payload.field) delete params.by_field
  return apiClient.get(url, {
    params
  })
}

export const getPgtProfile = userId => apiClient.get(`/v1/users/${userId}/pgt-profile`)

export const getPgtPartners = payload => apiClient
  .get(`/v1/pgt-profiles/${payload.id}/partners?limit=${payload.limit || 50}&offset=${payload.offset || 0}`)
  .then(data => data)
  .catch(error => error.response)

export const removePGTProgile = userId => apiClient.delete(`/v1/users/${userId}/pgt-profile`)

export const updatePgtPartner = payload => apiClient
  .patch(`/v1/pgt-profiles/${payload.pgtId}/partnerships`, {
    business_profile_id: payload.profileId || '',
    is_hidden: payload.isHidden || false
  })
  .then(data => data)
  .catch(error => error.response)

export const updatePgtPartnersPositions = payload => apiClient
  .post(`/v1/pgt-profiles/${payload.pgtId}/partners/positions`, {
    business_profile_id_to_insert: payload.to || '',
    business_profile_id_after_which_insert: payload.after || ''
  })
  .then(data => data)
  .catch(error => error.response)

export const addPgtPartner = payload => apiClient
  .post(`/v1/pgt-profiles/${payload.pgtId}/partnerships`, {
    business_profile_id: payload.partnerId,
    is_hidden: false
  })
  .then(data => data)
  .catch(error => error.response)
