import * as apiLongDelivery from '@/services/api/longDelivery'

export default {
  async getLongDelivery ({ commit }) {
    const res = await apiLongDelivery.getLongDeliveryParams().then(res => res.data)
    commit('setLongDelivery', res)
    return res
  },

  async editLongDeliveryParams ({ commit }, payload) {
    const res = await apiLongDelivery.editLongDeliveryParams(payload).then(res => res.data)
    commit('setLongDelivery', res)
    return res
  }
}
