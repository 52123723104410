/* eslint-disable camelcase */

import * as apiRequests from '@/services/api/pgt'
import * as profileApiRequests from '@/services/api/profiles'
import { ActionTree } from 'vuex'
import { PgtState, Profile } from './types'
import { RootState } from '@/store/types'

export const actions: ActionTree<PgtState, RootState> = {
  async getPgtProfiles ({ commit, getters }, payload: boolean) {
    const result = await apiRequests.getPgtProfiles(payload).then(result => result.data)
    commit('setPgtProfiles', result.items)
    commit('setPgtProfilesCount', result.total)
  },

  async getPgtProfile ({ commit, getters }, id: string) {
    const result = await apiRequests.getPgtProfile(id).then(result => result.data)
    const profile = getters.serializeProfile(result)
    commit('setPgtProfile', profile)
  },

  async removePGTProgile ({ commit, getters }, id: string) {
    const res = await apiRequests.removePGTProgile(id).then(res => res.data)
    return res
  },

  async updatePGTProfile ({ commit, getters }, payload: Profile) {
    const formattedData = getters.serializeProfileToUpdate(payload)

    const request = await profileApiRequests.updateProfile(formattedData, payload.id)
    if (request.status >= 200 && request.status < 300) {
      return request.data
    }
    return request
  },

  async serializeProfileToUpdate ({ commit, getters }, payload: Profile) {
    return getters.serializeProfileToUpdate(payload)
  },

  async getPgtPartners  ({ commit, getters }, payload: { id: string, limit: number, offset: number}) {
    const request = await apiRequests.getPgtPartners(payload)
    if (request.status >= 200 && request.status < 300) {
      await commit('setPgtPartners', request.data?.items || [])
      return request.data
    }
    return request
  },

  async updatePgtPartner ({ commit, getters }, payload: {
    pgtId: string,
    profileId: string,
    isHidden: boolean
  }) {
    const request = await apiRequests.updatePgtPartner(payload)
    if (request.status >= 200 && request.status < 300) {
      return request.data
    }
    return request
  },

  async updatePgtPartnersPositions ({ commit, getters }, payload: {
    pgtId: string,
    to: string,
    after: string
  }) {
    const request = await apiRequests.updatePgtPartnersPositions(payload)
    return request
  },

  async addPgtPartner ({ commit, getters }, payload: {
    pgtId: string,
    partnerId: string
  }) {
    const request = await apiRequests.addPgtPartner(payload)
    return request
  }
}

export default actions
