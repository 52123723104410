import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
// import createPersistedState from 'vuex-persistedstate'
import { RootState } from './types'

import auth from './modules/auth'
import user from './modules/user'
import common from './modules/common'
import profiles from './modules/profiles'
import posts from './modules/posts'
import catalog from './modules/catalog'
import areas from './modules/areas'
import promocodes from './modules/promocodes'
import orders from './modules/orders'
import requests from './modules/requests'
import pgt from './modules/pgt'
import reports from './modules/reports'
import longDelivery from './modules/longDelivery'
import chats from './modules/chats'

Vue.use(Vuex)

const storeOptions: StoreOptions<RootState> = {
  modules: {
    auth,
    user,
    common,
    profiles,
    catalog,
    posts,
    areas,
    promocodes,
    orders,
    requests,
    pgt,
    reports,
    longDelivery,
    chats
  }
  // plugins: [createPersistedState()]
}

const store = new Vuex.Store<RootState>(storeOptions)

export default store
