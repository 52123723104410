import { RequestState, Request } from './types'

export const initialRequest = {
  id: null
}

const state: RequestState = {
  requests: [],
  request: {} as Request,
  requestCount: 0 as number
}

export default state
