import { MutationTree } from 'vuex'
import { Transaction, OrdersState } from './types'

const mutations: MutationTree<OrdersState> = {
  setOrderTransactions (state: any, data: Transaction[]) {
    state.orderTransactions = data
  },

  setTransactionsDateRange (state: any, data) {
    state.transactionsDateRange = data
  },

  setSendingReadyReport (state: any, data) {
    state.sendingReadyReport = data
  },

  setOrdersAdministration (state: any, data) {
    state.ordersAdministration = data?.data
    state.ordersAdministrationCount = data?.total
  },

  setOrderOperators (state: any, data) {
    state.orderOperators = data
  }
}

export default mutations
