import { axiosClient as apiClient } from '@/services/api/apiClient'
import { DATE_SORTING } from '@/common/constants'
const BUSINESS_PROFILES_PREFIX = '/v1/business-profiles'

export const activate = (payload) => apiClient.post('/v1/allowed-businesses', payload)

const generateQuery = payload => {
  let query = `?limit=${payload.limit || 25}`
  if (payload.order_by) {
    query += `&order_by=${payload.order_by}`
  }
  if (payload.isAllowedForBusiness) {
    query += `&isAllowedForBusiness=${payload.isAllowedForBusiness}`
  }
  if (payload.filter_field) {
    query += `&filter_field=${payload.filter_field}`
  }
  if (payload.filter_fields) {
    // params.filter_fields = payload.filter_fields.map(value => { return { filter_fields: value } })
    // params.filter_fields = payload.filter_fields.join(',')
    payload.filter_fields.forEach(element => {
      query += `&filter_fields=${element}`
    })
  }
  if (payload.profile_type) {
    query += `&profile_type=${payload.profile_type}`
  }
  if (payload.filter_value) {
    query += `&filter_value=${payload.filter_value}`
  }
  if (payload.offset) {
    query += `&offset=${payload.offset}`
  }
  if (payload.order_transactions_from_date) {
    query += `&order_transactions_from_date=${payload.order_transactions_from_date}`
  }
  if (payload.order_transactions_to_date) {
    query += `&order_transactions_to_date=${payload.order_transactions_to_date}`
  }

  return query
}

export const getProfiles = (payload) => {
  const url = payload.field ? `/v1/users/${payload.field}` : '/v1/users'
  const query = generateQuery({ ...payload, order_by: payload.order_by || DATE_SORTING.descending })

  return apiClient
    .get(url + query)
    .then(data => data)
    .catch(error => error.response)
}

export const getBisinessProfiles = (payload) => {
  const url = payload.field ? `/v1/admin/business-profiles/${payload.field}` : '/v1/admin/business-profiles'
  const query = generateQuery(payload)

  return apiClient
    .get(url + query)
    .then(data => data)
    .catch(error => error.response)
}

export const getBusinessProfile = (profileId) => apiClient
  .get(`/v1/users/${profileId}/profiles`)
  .then(data => data)
  .catch(error => error.response)

export const updateProfile = (profile, profileId) => apiClient
  .patch(`/v1/users/${profileId}/profiles`, profile)
  .then(data => data)
  .catch(error => error.response)

export const deleteBusinessWorkTimes = (payload) => apiClient.delete(`/v1/business-profiles/${payload.profileId}/business-work-time`, { data: payload.workTimesIds })

export const getProfileRequisites = (profileId) => apiClient.get(`${BUSINESS_PROFILES_PREFIX}/${profileId}/business-requisites`)

export const updateProfileRequisites = (payload) => apiClient
  .patch(`${BUSINESS_PROFILES_PREFIX}/${payload.profileId}/business-requisites`, payload.requisites)
  .then(data => data)
  .catch(error => error.response)

export const createProfileRequisites = (payload) => apiClient
  .post(`${BUSINESS_PROFILES_PREFIX}/${payload.profileId}/business-requisites`, payload.requisites)
  .then(data => data)
  .catch(error => error.response)

export const updateDeliveryLocation = ({ id, byField, data }) => apiClient.patch(`/v1/users/${id}/business-locations?by_field=${byField}`, data)

export const getBusinessProfilesForCopyCatalog = (params) => apiClient.get(BUSINESS_PROFILES_PREFIX, { params })

// export const getBusinessProfile = (profileId) => apiClient.get(`/business-profiles/${profileId}/catalog-contents`)
