import Vue from 'vue'

export const cloneDeep = (obj: any) => JSON.parse(JSON.stringify(obj))

export const setDocumentTitle = (route: any) => {
  if (route.meta.title) {
    document.title = `${route.meta.title} — ${process.env.VUE_APP_NAME}`
  } else {
    document.title = process.env.VUE_APP_NAME
  }
}

export const handleErrors = (error: any) => {
  console.log(error)
  if (error.response.data.detail) {
    let htmlResponse = ''

    for (let i = 0; i < error.response.data.detail.length; i++) {
      const item = error.response.data.detail[i]
      htmlResponse += `<li>${item.msg}</li>`
    }
    Vue.$toast.error(`<ul>${htmlResponse}</ul>`)
  }
}

let uniqueIdCounter = 0
export const getUniqueId = (): string => {
  return '' + uniqueIdCounter++ + new Date().getTime().toString()
}

export const formatTime = (time) => time < 10 ? `0${time}` : time

export default {
  cloneDeep,
  setDocumentTitle,
  handleErrors,
  formatTime
}
