import { GetterTree } from 'vuex'
import { Product, ProductsState, Category, SerializedProduct, Catalog, CatalogCategory, CatalogChildCategory } from './types'
import { RootState } from '@/store/types'

const getters: GetterTree<ProductsState, RootState> = {
  getNewProduct: () => (): Product => ({
    name: '',
    image_url: '',
    description: '',
    category: {
      name: ''
    },
    category_id: null,
    delivery_methods: [
      {
        delivery_method: 'delivery',
        discount: 0,
        is_hidden: false,
        package_price: 0
      },
      {
        delivery_method: 'pickup',
        discount: 0,
        is_hidden: false,
        package_price: 0
      }
    ],
    attributes: {
      name: '',
      value: 1,
      unit: 'шт'
    },
    price: 0,
    is_hidden: false,
    stock_qty: 1,
    discount: 0
  }),

  getNewCatalog: (): Catalog => {
    return {
      created_at: null,
      updated_at: null,
      name: null,
      image_url: null,
      delivery_price: 0,
      is_there_free_delivery: false,
      order_price_to_free_delivery: 0,
      business_profile_id: null,
      is_hidden: false
    }
  },

  getNewCatalogCategory: (): CatalogCategory => {
    return {
      name: null,
      bg_color: '#f4f4f5',
      image_url: null,
      is_hidden: true
    }
  },

  getNewCatalogChildCategory: (): CatalogChildCategory => {
    return {
      name: null,
      is_hidden: false
    }
  },

  serializeProduct: () => (data: Product): SerializedProduct => {
    return ({
      id: data.id,
      name: data.name,
      category: data.category,
      image_url: data.image_url,
      description: data.description,
      delivery_methods: data.delivery_methods,
      attributes: {
        name: data.attributes.name,
        value: data.attributes.value,
        unit: data.attributes.unit
      },
      price: data.price,
      is_hidden: data.is_hidden,
      stock_qty: data.stock_qty,
      discount: data.discount
    })
  },

  deserializeProduct: () => (data: any): Product => {
    return ({
      attributes: {
        name: data.attributes.name,
        value: data.attributes.value,
        unit: data.attributes.unit
      },
      delivery_methods: [data.delivery_method],
      description: data.description,
      discount: 0,
      id: data.id,
      image_url: data.image_url,
      is_hidden: false,
      name: data.name,
      price: 0,
      stock_qty: 1
    })
  }
}

export default getters
