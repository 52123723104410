import { axiosClient as apiClient } from '@/services/api/apiClient'

export const getBusinessCategories = () => apiClient.get('/v1/business-categories')

export const getBusinessFilters = () => apiClient.get('/v1/business-filters', {
  params: {
    is_admin: true
  }
})

export const uploadMedia = (file) => apiClient.post('/v1/files', file, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})
