import { axiosClient as apiClient } from '@/services/api/apiClient'

export const getAreas = () => apiClient.get(
  '/v1/areas'
)

export const getBusinessProfiles = (areaId) => apiClient.get(
  '/v1/business-profiles',
  {
    params: {
      area_id: areaId,
      limit: 250
    }
  }
)

export const createArea = (area) => {
  return apiClient.post(
    '/v1/areas',
    area
  )
}

export const updateArea = area => {
  return apiClient.patch(
    `/v1/areas/${area.id}`,
    area
  )
}

export const deleteArea = areaId => apiClient.delete(`/v1/areas/${areaId}`)
