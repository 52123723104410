

















































import Vue, { PropType } from 'vue'
import { uploadTinkoffReport } from '@/services/api/reports'

export default Vue.extend({
  name: 'UploadTinkoffModal',
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },

  data () {
    return {
      isHovered: false,
      showed: false,
      file: {
        name: null,
        body: null
      },
      success: false,
      failed: false
    }
  },

  watch: {
    value (val) {
      this.showed = val
      this.clearData()
      this.failed = false
      this.success = false
    }
  },

  computed: {
    title () {
      if (this.success) {
        return 'Отчет Tinkoff успешно загружен!'
      }
      if (this.failed) {
        return 'Ошибка загрузки. Попробуйте позже'
      }
      return 'Загрузить отчет Tinkoff'
    },
    isButtonDisabled () {
      if ((!this.file.name && (this.success || this.failed)) || this.file.name) {
        return false
      }
      return true
    }
  },

  methods: {
    loadFile (event) {
      const { files } = event.target

      this.file = {
        name: files[0].name,
        body: files[0]
      }
    },
    handleAction () {
      if (this.success || this.failed) this.onModalHidden()
      else this.upload()
    },
    async upload () {
      if (!this.file.body) this.$toast.error('Файл не загружен')
      this.loading = true
      const formdata = new FormData()
      formdata.append('tinkoff_report_file', this.file.body)
      const response = await uploadTinkoffReport(formdata)
      if (response.status >= 200 && response.status < 300) {
        this.success = true
      } else {
        console.error(response.data.detail)
        this.$toast.error(response.data.detail)
        this.failed = true
      }
      this.loading = false
    },
    onModalHidden () {
      this.showed = false
      this.$emit('modal-hidden')
      this.clearData()
    },
    clearData () {
      this.file = {
        name: null,
        body: null
      }
    }
  }
})
