import { axiosClient as apiClient } from '@/services/api/apiClient'

export const getCatalog = (payload) => apiClient
  .get(`/v1/business-profiles/${payload.profileId}/catalogs/${payload.catalogId}`)
  .then(data => data)
  .catch(error => error.response)

export const getCategoriesHierarchy = (payload) => apiClient.get(
  `/v1/catalogs/${payload.catalogId}/categories-hierarchy`
)

export const updateCatalog = (payload) => {
  return apiClient.patch(
    `/v1/business-profiles/${payload.profileId}/catalogs/${payload.catalogId}`,
    payload.data
  )
}

export const getCatalogCategories = (payload) => apiClient
  .get(`/v1/catalogs/${payload.catalogId}/categories`,
    { params: payload.params }
  )
  .then(data => data)
  .catch(error => error.response)

export const updateCatalogCategory = (payload) => {
  return apiClient.patch(
    `/v1/catalogs/${payload.catalogId}/categories/${payload.categoryId}`,
    payload.data
  )
}

export const updateCatalogChildCategory = (payload) => {
  return apiClient.patch(
    `/v1/categories/${payload.categoryId}/children/${payload.data.id}`,
    payload.data
  )
}

export const getCatalogChildCategories = (categoryId) => apiClient
  .get(`/v1/categories/${categoryId}/children?include_system=true&include_hidden=true`)
  .then(data => data)
  .catch(error => error.response)

export const getProductsByCategory = (categoryId) => apiClient.get(
  '/v1/admin/products', {
    params: {
      category_id: categoryId
    }
  }
)

export const getCatalogContent = (payload) => apiClient
  .get(`/v1/categories/${payload.sectionId}/content${
    payload.deliveryMethod ? '?delivery_method=' + payload.deliveryMethod : ''
  }`)
  .then(data => data)
  .catch(error => error.response)

export const getCatalogs = (payload) => apiClient
  .get(`/v1/business-profiles/${payload.businessProfileId}/catalogs`)
  .then(data => data)
  .catch(error => error.response)

export const createCatalog = (payload) => {
  return apiClient.post(
    `/v1/business-profiles/${payload.profileId}/catalogs`,
    payload.data
  )
}

export const deleteCatalog = (payload) => apiClient.delete(`/v1/business-profiles/${payload.profileId}/catalogs/${payload.catalogId}`)

export const deleteCatalogCategory = (payload) => apiClient.delete(`/v1/categories/${payload.categoryId}`)

export const createCatalogCategory = (payload) => {
  return apiClient.post(
    `/v1/catalogs/${payload.catalogId}/categories?include_system=true&include_hidden=true`,
    payload.data
  )
}

export const createCatalogChildCategory = (payload) => {
  return apiClient.post(
    `/v1/categories/${payload.categoryId}/children`,
    payload.data
  )
}

export const createProduct = (payload) => {
  return apiClient.post(
    '/v1/admin/products',
    payload.data
  )
}

export const deleteProduct = (productId) => apiClient.delete(`/v1/products/${productId}`)

export const updateProduct = (product) => {
  return apiClient.patch(
    `/v1/admin/products/${product.id}`,
    product
  )
}

export const addPost = (payload) => {
  return apiClient.post(
    '/v2/posts',
    payload
  )
}

export const updateProductsPosition = (payload) => {
  return apiClient.post(
    `/v1/categories/${payload.categoryId}/products/positions`,
    {
      product_id_to_insert: payload.productInsertId,
      product_id_after_which_insert: payload.productAfterInsertId
    }
  )
}

export const updateCategoriesPosition = (payload) => {
  return apiClient.post(
    `/v1/catalogs/${payload.catalogId}/categories/positions`,
    {
      category_id_to_insert: payload.categoryInsertId,
      category_id_after_which_insert: payload.categoryAfterInsertId
    }
  )
}

export const updateChildCategoriesPosition = (payload) => {
  return apiClient.post(
    `/v1/categories/${payload.categoryId}/children/positions`,
    {
      category_id_to_insert: payload.categoryInsertId,
      category_id_after_which_insert: payload.categoryAfterInsertId
    }
  )
}

export const searchCatalogProducts = (payload) => {
  return apiClient.get(`/v1/admin/search/${payload.profileId}/${payload.catalogId}/product`, {
    params: {
      search_field: payload.searchField
    }
  })
}

export const copyCatalog = ({ oldCatalogId, newCatalogId }) => {
  return apiClient.post(`v1/catalog/${oldCatalogId}/new_catalog/${newCatalogId}`)
}
