import { cloneDeep } from '@/utils/functions'
import { MutationTree } from 'vuex'
import { PgtState } from './types'
import { initialPgtProfile } from './state'

const mutations: MutationTree<PgtState> = {
  setPgtProfiles (state, payload) {
    state.pgtProfiles = payload
  },

  setPgtProfilesCount (state, payload) {
    state.pgtProfilesCount = payload
  },

  setPgtProfile (state, payload) {
    state.pgtProfile = payload
  },

  setPgtPartners (state, payload) {
    state.partners = payload
  },

  resetProfile (state: any) {
    state.profile = cloneDeep(initialPgtProfile)
  }
}

export default mutations
