import * as apiCatalog from '@/services/api/catalog'
import { Catalog, CatalogCategory, CatalogChildCategory, Product } from './types'

export default {
  async getCatalogs ({ commit }, payload) {
    const request = await apiCatalog.getCatalogs(payload).then(res => res)

    if (request.status >= 200 && request.status < 300) {
      commit('setCatalogs', request.data.items)
      return request.data
    }
  },

  async getCatalogsForCopy ({ commit }, payload) {
    try {
      const { data } = await apiCatalog.getCatalogs(payload)
      if (data) {
        commit('setCatalogsForCopy', data.items)
        return data
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async copyCatalog ({ commit }, { oldCatalogId, newCatalogId }) {
    try {
      const { data } = await apiCatalog.copyCatalog({ oldCatalogId, newCatalogId })
      if (data) {
        console.log(data)
        return data
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async getCategoriesHierarchy ({ commit }, payload) {
    const res = await apiCatalog.getCategoriesHierarchy(payload).then(res => res.data)
    commit('setCategoriesHierarchy', res)
    return res
  },

  async createCatalog ({ commit }, payload: { profileId: string, data: Catalog }) {
    const res = await apiCatalog.createCatalog(payload)
    commit('addCatalog', res.data)
    return res
  },

  async deleteCatalog ({ commit }, payload: { profileId: string, catalogId: string }) {
    const res = await apiCatalog.deleteCatalog(payload)
    commit('removeCatalog', payload.catalogId)
    return res
  },

  async deleteCatalogCategory ({ commit }, payload: { categoryId: string }) {
    const res = await apiCatalog.deleteCatalogCategory(payload)
    commit('removeCatalogCategory', payload.categoryId)
    return res
  },

  async deleteCatalogChildCategory ({ commit }, payload: { categoryId: string }) {
    const res = await apiCatalog.deleteCatalogCategory(payload)
    commit('removeCatalogChildCategory', payload.categoryId)
    return res
  },

  async updateCatalog ({ commit }, payload: {profileId: string, catalogId: string, data: Catalog }) {
    const res = await apiCatalog.updateCatalog(payload)
    commit('updateCatalog', res.data)
    return res
  },

  async getCatalog ({ commit }, payload: { profileId: string, catalogId: string }) {
    const request = await apiCatalog.getCatalog(payload).then(res => res)

    if (request.status >= 200 && request.status < 300) {
      commit('setCatalog', request.data)
      return request.data
    }
  },

  async createCatalogCategory ({ commit }, payload: { catalogId: string, data: CatalogCategory }) {
    const res = await apiCatalog.createCatalogCategory(payload)
    commit('addCatalogCategory', res.data)
    return res
  },

  async updateCatalogCategory ({ commit }, payload: {
    catalogId: string
    categoryId: string
    data: CatalogCategory
  }) {
    const res = await apiCatalog.updateCatalogCategory(payload)
    commit('updateCatalogCategory', res.data)
    return res
  },

  async updateCatalogChildCategory ({ commit }, payload: {
    categoryId: string
    data: CatalogChildCategory
  }) {
    const res = await apiCatalog.updateCatalogChildCategory(payload)
    commit('updateCatalogChildCategory', payload)
    return res
  },

  async createCatalogChildCategory ({ commit }, payload: { categoryId: string, data: CatalogChildCategory }) {
    const res = await apiCatalog.createCatalogChildCategory(payload)
    commit('addCatalogChildCategory', { categoryId: payload.categoryId, childCategory: res.data })
    return res
  },

  async getCatalogCategories ({ commit }, payload: { catalogId: string, includeSystem: boolean, includeHidden: boolean }) {
    const params = {
      include_system: payload?.includeSystem || true,
      include_hidden: payload?.includeHidden || true
    }
    const request = await apiCatalog.getCatalogCategories({ ...payload, params })

    if (request.status >= 200 && request.status < 300) {
      commit('setCatalogCategories', request.data)
      return request.data
    }
    return request
  },

  async getCatalogChildCategories ({ commit, state }, payload: { catalogId: string }) {
    const request = await apiCatalog.getCatalogChildCategories(payload?.catalogId || state.selectedCatalogCategory.id)

    if (request.status >= 200 && request.status < 300) {
      commit('setCatalogChildCategories', request.data)
      return request.data
    }
    return request
  },

  async getCatalogContent ({ commit }, payload: { sectionId: string, deliveryMethod: string }) {
    const request = await apiCatalog.getCatalogContent(payload)
    if (request.status >= 200 && request.status < 300) {
      commit('setCatalogContent', request.data)
      return request.data
    }
    return request
  },

  // async createCatalog ({ commit, getters }, { profileId, data }) {
  //   const res = await apiCatalog.createCatalog({ profileId, data })
  //   commit('setCatalog', res)
  //   return res
  // },

  async getProductsByCategory ({ commit }, payload: { categoryId: string }) {
    const res = await apiCatalog.getProductsByCategory(payload.categoryId)
    commit('setCatalogChildCategoryProducts', res.data)
    return res
  },

  async createProduct ({ commit }, payload: { data: Product }) {
    // const product = getters.serializeProduct(data)
    const res = await apiCatalog.createProduct(payload)
    commit('addProduct', payload.data)
    return res
  },

  async deleteProduct ({ commit, getters }, productId) {
    const res = await apiCatalog.deleteProduct(productId)
    return res
  },

  async updateProduct ({ commit }, product: Product) {
    // product = getters.serializeProduct(product)
    const res = await apiCatalog.updateProduct(product)
    commit('updateProduct', res.data)
    return res
  },

  async updateProductsPosition ({ commit }, payload: {
    categoryId: string
    productInsertId: string
    productAfterInsertId: string
  }) {
    commit('sortProducts', payload)
    const res = await apiCatalog.updateProductsPosition(payload)
    return res
  },

  async updateCategoriesPosition ({ commit }, payload: {
    catalogId: string
    categoryInsertId: string
    categoryAfterInsertId: string
  }) {
    const res = await apiCatalog.updateCategoriesPosition(payload)
    commit('sortCategories', payload)
    return res
  },

  async updateChildCategoriesPosition (context, payload: {
    categoryId: string
    categoryInsertId: string
    categoryAfterInsertId: string
  }) {
    const res = await apiCatalog.updateChildCategoriesPosition(payload)
    // commit('sortChildCategories', payload)
    return res
  },

  async searchCatalogProducts ({ commit }, payload: {
    profileId: string
    catalogId: string
    searchField: string
  }) {
    try {
      const { data } = await apiCatalog.searchCatalogProducts(payload)
      if (data) {
        commit('setCatalogChildCategoryProducts', data)
        return Promise.resolve(data)
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
