import { ROLE_GUEST, ROLE_ADMIN } from '@/common/constants'

// const TOKEN_KEY = 'session'
const ACCESS_KEY = 'perimetr-auth'

export const setAccess = (accessArg) => {
  // let access = accessArg
  // if (typeof access !== 'object') {
  //   try {
  //     access = JSON.parse(accessArg)
  //   } catch {
  //     throw new TypeError('argument must be object or JSON string')
  //   }
  // }
  // if (!access.roles.length) throw new Error('user has no roles')

  localStorage.setItem(ACCESS_KEY, JSON.stringify({
    accessToken: accessArg.access_token,
    expired: Date.now() + accessArg.exp, // accessArg.exp,
    refreshToken: accessArg.refresh_token,
    // permissions: access.permissions,
    role: accessArg.role ? accessArg.role : ROLE_ADMIN
  }))
}

export const getAccess = () => {
  const access = JSON.parse(localStorage.getItem(ACCESS_KEY))
  if (access) return access

  return {
    accessToken: null,
    expired: 0,
    refreshToken: null,
    // permissions: null,
    role: ROLE_GUEST
  }
}

export const logout = () => {
  localStorage.removeItem(ACCESS_KEY)
}

export const getRole = () => getAccess().role

export default {
  setAccess,
  getAccess,
  logout,
  getRole
}
