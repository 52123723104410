import { GetterTree } from 'vuex'
import { ReportsState, Reports, AvailableDataRage } from './types'
import { RootState } from '@/store/types'

const getters: GetterTree<ReportsState, RootState> = {
  serializeAvailableDataRange: () => (data: { 'from_date': string, 'to_date': string }): AvailableDataRage => ({
    fromDate: Date.parse(data.from_date),
    toDate: Date.parse(data.to_date)
  })
}

export default getters
