














































































import Vue from 'vue'
import Logo from '@/components/Logo.vue'
import authService from '@/services/auth'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'Sidebar',

  components: {
    Logo
  },

  data () {
    return {
      visible: false,
      navItems: [
        {
          to: '/profiles',
          title: 'Профили',
          icon: 'list',
          collapsed: false,
          name: 'profile',
          childs: [
            {
              to: '/profiles/requests',
              title: 'Запросы на БП',
              icon: 'file-add'
            },
            {
              to: '/profiles/active',
              title: 'Бизнес-профили',
              icon: 'check-circle'
            },
            {
              to: '/profiles/clients',
              title: 'Обычные профили',
              icon: 'check-circle'
            }
            // {
            //   to: '/temp',
            //   title: 'Скрытые',
            //   icon: 'eye-close-line'
            // },
            // {
            //   to: '/temp',
            //   title: 'Архивные',
            //   icon: 'archive'
            // },
            // {
            //   to: '/temp',
            //   title: 'Отклоненные',
            //   icon: 'cancel'
            // },
            // {
            //   to: '/temp',
            //   title: 'Черновики',
            //   icon: 'edit'
            // }
          ]
        },
        {
          to: '/areas',
          title: 'Зоны perimetr',
          icon: 'location-v3',
          name: 'areas'
        },
        {
          to: '/promocodes',
          title: 'Промокоды perimetr',
          icon: 'discount',
          name: 'promocodes'
        },
        {
          to: '/pgt',
          title: 'Профили генераторы трафика',
          icon: 'award',
          name: 'pgt'
        },
        {
          to: '/orders',
          title: 'Заказы',
          icon: 'star',
          name: 'orders'
        },
        {
          to: '/long-delivery',
          title: 'Дальние доставки',
          icon: 'star',
          name: 'longDelivery'
        },
        {
          to: '/chats',
          title: 'Чаты',
          icon: 'chat',
          name: 'chats'
        }
      ]
    }
  },

  watch: {
    visible (val) {
      const bodyClassname = 'sidebar-opened'
      if (val) {
        document.body.classList.add(bodyClassname)
      } else {
        document.body.classList.remove(bodyClassname)
      }
    },

    $route () {
      this.visible = false
    }
  },

  computed: {
    ...mapState('chats', ['unreadChatsCount'])
  },

  methods: {
    checkIsActive (currentPath) {
      return this.$route.matched.some(({ path }) => path.includes(currentPath))
    },

    handleLogout () {
      authService.logout()
      this.$router.push('/auth')
    },

    toggleCollapseNavItem (itemName: string) {
      // const item = this.navItems.find(item => item.title === itemName)
      // item.collapsed = !item.collapsed
    }
  }
})
