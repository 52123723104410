import { MutationTree } from 'vuex'
import { AreasState, Area } from './types'

const mutations: MutationTree<AreasState> = {
  setAreas (state, area: Area[]) {
    state.areas = area
  },

  addArea (state, area: Area) {
    state.areas.push(area)
  },

  updateArea (state: any, area: Area) {
    const areaIndex = state.areas.findIndex((item: { id: any }) => item.id === area.id)
    state.areas.splice(areaIndex, 1, area)
  },

  deleteArea (state, areaId: string) {
    const index = state.areas.findIndex(item => item.id === areaId)
    state.areas.splice(index, 1)
  }
}

export default mutations
