










import Vue from 'vue'
export default Vue.extend({
  name: 'Loading',

  props: {
    isFixed: {
      type: Boolean,
      default: () => false
    },
    size: {
      type: Number,
      default: () => 3
    }
  }
})
