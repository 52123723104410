import axios from 'axios'
import * as authService from '@/services/auth'
import * as authApi from '@/services/api/auth'

/**
 * Сконфигурированный axios клиент
 * */
const axiosClient = axios.create({
  timeout: 100000,
  baseURL: `https://${process.env.VUE_APP_BASE_API_DOMAIN}/api`,
  headers: {
    'Content-Type': 'application/json'
  },
  maxContentLength: 10000
})

/**
 * Добавление JSON TOKEN в каждый запрос
 * */

axiosClient.interceptors.request.use(
  config => {
    const accessToken = authService.getAccess().accessToken
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  error => Promise.reject(error)
)

// ******************************************
// client for update refresh token

const axiosRefreshClient = axios.create({
  timeout: 100000,
  baseURL: `https://${process.env.VUE_APP_BASE_API_DOMAIN}/api`,
  headers: {
    'Content-Type': 'application/json'
  },
  maxContentLength: 10000
})
axiosRefreshClient.interceptors.request.use(
  config => {
    const { refreshToken } = authService.getAccess()
    if (refreshToken) {
      config.headers.Authorization = `Bearer ${refreshToken}`
    }
    return config
  },
  error => Promise.reject(error)
)
// ******************************************

// ******************************************
// client for update refresh token

const axiosAnonimusClient = axios.create({
  timeout: 100000,
  baseURL: `https://${process.env.VUE_APP_BASE_API_DOMAIN}/api`,
  headers: {
    'Content-Type': 'application/json'
  },
  maxContentLength: 10000
})
axiosAnonimusClient.interceptors.request.use(
  config => {
    const { accessToken } = authService.getAccess()
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  error => Promise.reject(error)
)
// ******************************************

axiosClient.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    const originalConfig = err.config
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true
        try {
          const responseRefresh = await authApi.refreshToken({})
          const { accessToken } = responseRefresh.data
          authService.setAccess(responseRefresh.data)
          axiosClient.defaults.headers.Authorization = accessToken
          return axiosClient(originalConfig)
        } catch (_error) {
          if (_error.error_sms_code && _error.error_sms_code) {
            return Promise.reject(_error.error_sms_code)
          }
          return Promise.reject(_error)
        }
      }
      if (err.response.status === 403) {
        return Promise.reject(err?.response?.data || err.response)
      }
    }
    return Promise.reject(err)
  }
)

axiosClient.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalConfig = error.config
    if (
      (error?.response?.status === 401 && !originalConfig._retry) ||
        (error?.error_sms_code?.http_status_code === 401 && !originalConfig._retry)
    ) {
      originalConfig._retry = true
      // Do something, call refreshToken() request for example;
      // return a request
      return axiosClient
    }

    // handle for clearing tokens for web view
    if (([403, 500].includes(error?.response?.status) ||
      [403, 500].includes(error?.error_sms_code?.http_status_code)) &&
      document.location.pathname.includes('app-preview')) {
      authService.logout()
      return Promise.reject(error)
    }

    if (error?.response?.status === 403 ||
      error?.error_sms_code?.http_status_code === 403) {
      authService.logout()
      return Promise.reject(error)
    }

    if (error?.response?.status < 200 || error?.response?.status >= 300 ||
        error?.error_sms_code?.http_status_code < 200 || error?.error_sms_code?.http_status_code >= 300) {
      // Do something
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

export { axiosClient, axiosRefreshClient, axiosAnonimusClient }
