import { OrdersState } from '@/store/modules/orders/types'

const state: OrdersState = {
  orderTransactions: [],
  transactionsTotalScore: null,
  transactionsDateRange: [],
  sendingReadyReport: null,
  ordersAdministration: [],
  ordersAdministrationCount: 0,
  orderOperators: []
}

export default state
