import { MutationTree } from 'vuex'
import { LongDeliveryState, LongDelivery } from './types'

const mutations: MutationTree<LongDeliveryState> = {
  setLongDelivery (state, payload: LongDelivery) {
    state.long_delivery = payload
  }
}

export default mutations
