import { LongDeliveryState } from './types'

const state: LongDeliveryState = {
  long_delivery: {
    min_order_price: 500,
    delivery_price: 200,
    delivery_time: 60,
    radius: 10
  }
}

export default state
