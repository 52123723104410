import { GetterTree } from 'vuex'
import { Profile, PgtState, UpdateProfileBody } from './types'
import { RootState } from '@/store/types'

const getters: GetterTree<PgtState, RootState> = {

  serializeProfile: () => (data: Profile): Profile => ({
    avatar_url: data.avatar_url,
    created_at: data.created_at,
    email: data.email,
    id: data.id,
    is_subscribed: data.is_subscribed,
    likes: data.likes,
    location: {
      address: data.location?.address || '',
      geo_point: {
        longitude: data.location?.geo_point?.longitude || 0,
        latitude: data.location?.geo_point?.latitude || 0
      },
      id: data.location?.id || ''
    },
    name: data.name,
    phone: `+${data.phone}`,
    profile_type: data.profile_type,
    subscribers: data.subscribers,
    updated_at: data.updated_at,
    username: data.username,
    qr_code_url: data.qr_code_url,
    pgt_profile: data.pgt_profile,
    business_profile: { ...data.business_profile, contact_phone: `+${data.business_profile.contact_phone}` },
    partners_count: data.partners_count
  }),

  serializeProfileToUpdate: () => (data: Profile): UpdateProfileBody => ({
    avatar_url: data.avatar_url,
    created_at: data.created_at,
    email: data.email,
    id: data.id,
    is_subscribed: data.is_subscribed,
    likes: data.likes,
    location: {
      address: data.location?.address || '',
      geo_point: {
        longitude: data.location?.geo_point?.longitude || 0,
        latitude: data.location?.geo_point?.latitude || 0
      },
      id: data.location?.id || ''
    },
    name: data.name,
    phone: data.phone,
    profile_type: data.profile_type,
    subscribers: data.subscribers,
    updated_at: data.updated_at,
    username: data.username,
    qr_code_url: data.qr_code_url,
    pgt_profile: data.pgt_profile,
    profile_data: {
      category_id: data.business_profile.category.id,
      contact_phone: data.business_profile.contact_phone,
      description: data.business_profile.description,
      is_hidden: data.business_profile.is_hidden,
      is_verified: data.business_profile.is_verified,
      website: data.business_profile.website,
      juridical_address: data.business_profile.juridical_address,
      work_schedule: data.business_profile.work_schedule,
      work_time_schedule: data.business_profile.work_time_schedule
    },
    partners_count: data.partners_count
  })
}

export default getters
