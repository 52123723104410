import { axiosClient as apiClient, axiosRefreshClient as apiToken, axiosAnonimusClient } from '@/services/api/apiClient'

const AUTH_PREFIX = '/v1/authorization'

export const login = (payload) => apiClient.post(`${AUTH_PREFIX}/get-admin-tokens`, payload)

export const anonymousSession = () => axiosAnonimusClient
  .post(`${AUTH_PREFIX}/create-anonymous-session`)
  .then(data => data)
  .catch(error => error.response)

export const refreshToken = (payload) => apiToken.post(`${AUTH_PREFIX}/refresh-tokens`, payload)
