import { cloneDeep } from 'lodash'
import initialRequest from './state'
import { MutationTree } from 'vuex'
import { ReportsState } from './types'

const mutations: MutationTree<ReportsState> = {
  setAvailableDataRange (state, payload) {
    state.availableDataRage = payload
  },
  // payload as id of the profile
  addSelectedProfile (state, payload) {
    state.selectedProfiles.push(payload)
  },
  // payload as id of the profile
  removeSelectedProfile (state, payload) {
    state.selectedProfiles = state.selectedProfiles.filter(id => id !== payload)
  },
  clearSelectedProfiles (state, payload) {
    state.selectedProfiles = []
  }
}

export default mutations
