import { GUEST_PAGES, ROLE_ADMIN } from '@/common/constants'
import * as authService from '@/services/auth'
// import { notifyError } from '@/utils/notify'

export default (to, from, next) => {
  const currentRole = authService.getRole()

  if (!currentRole) {
    authService.logout()
    // notifyError(message)
    this.$toast.success('У пользователя нет роли')
    return next('/auth')
  }

  /* Если маршрут вложенный и у него нет своих ограничений по ролям,
  *  то взять ограничения у родительского маршрута */
  let roles = to.meta?.roles

  if (!roles) {
    for (let i = to.matched.length - 1; i >= 0; i--) {
      const route = to.matched[i]
      if (!route.meta?.roles) continue
      roles = route.meta.roles
      break
    }
  }

  // Если пользователь не авторизован
  if (currentRole !== ROLE_ADMIN && !GUEST_PAGES.includes(to.name)) {
    return next('/auth')
  }

  // Если уже авторизован как администратор, но переходит на auth admins
  if (currentRole === ROLE_ADMIN && to.name === 'auth') {
    return next('/')
  }

  if (to.path === '/404' || roles.some(role => currentRole.includes(role))) {
    return next()
  }

  return next('/404')
}
