import { GetterTree } from 'vuex'
import { Promocode, PromocodesState } from './types'
import { RootState } from '@/store/types'

const getters: GetterTree<PromocodesState, RootState> = {
  getNewPromocode: () => (): Promocode => ({
    id: null,
    description: null,
    code: null,
    discount_type: 'fixed_discount',
    type: 'subscription_promo_code',
    discount_amount: null,
    min_order_amount: null,
    subscription_message: null,
    business_profile_id: null
  })

  // serializePromocode: () => (data: Promocode): SerializedPromocode => {
  //   return ({
  //     id: data.id,
  //     name: data.name,
  //     category: data.category,
  //     image_url: data.image_url,
  //     description: data.description,
  //     delivery_methods: data.delivery_methods,
  //     attributes: {
  //       name: data.attributes.name,
  //       value: data.attributes.value,
  //       unit: data.attributes.unit
  //     },
  //     price: data.price,
  //     is_hidden: data.is_hidden,
  //     stock_qty: data.stock_qty,
  //     discount: data.discount
  //   })
  // },

  // deserializePromocode: () => (data: any): Promocode => {
  //   return ({
  //     attributes: {
  //       name: data.attributes.name,
  //       value: data.attributes.value,
  //       unit: data.attributes.unit
  //     },
  //     description: data.description,
  //     discount: 0,
  //     id: data.id,
  //     image_url: data.image_url,
  //     is_hidden: false,
  //     name: data.name,
  //     price: 0,
  //     stock_qty: 1
  //   })
  // }
}

export default getters
