import { axiosClient as apiClient } from '@/services/api/apiClient'
import { PAGINATION_LIMIT } from '@/common/constants'

export const getOrderTransactions = (payload) => apiClient.get(
  '/v1/order-transactions',
  {
    params: {
      from_date: payload.from,
      to_date: payload.to,
      business_profile_id: payload.profileId,
      limit: payload.limit || PAGINATION_LIMIT,
      offset: payload.offset || 0
    }
  }
)

export const getReports = (payload) => apiClient.get(
  '/v1/reports',
  {
    params: {
      from_date: payload.from,
      to_date: payload.to,
      business_profile_id: payload.profileId,
      limit: payload.limit || PAGINATION_LIMIT,
      offset: payload.offset || 0
    }
  }
)

export const createReport = (payload) => {
  return apiClient.post(
    '/v1/reports', {
      from_date: payload.from,
      to_date: payload.to,
      business_profile_id: payload.businessProfileId
    }
  )
}

export const sendReportToEmail = (payload) => {
  return apiClient.post(
    `/v1/reports/${payload.reportId}/email`, {
      subject: payload.subject,
      body: payload.body,
      recipient: payload.recipientEmail
    }
  )
}

export const archivePromocode = (promocodeId) => apiClient.post(`/v1/promo-codes/${promocodeId}/archive`)

export const getOrdersAdministration = (payload) => apiClient.get('/v1/orders_administration', {
  params: payload
})

export const editOrderAdministration = ({ id, payload }) => apiClient.patch(`/v1/orders_administration/${id}`, payload)

export const getOrderOperators = () => apiClient.get('/v1/order_operators')

export const createOrdersReport = (payload) => apiClient.post('/v1/order-reports', payload)
