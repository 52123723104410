/* eslint-disable camelcase */
import type { GetterTree } from 'vuex'
import { ChatsState } from './types'
import { RootState } from '@/store/types'
import { uniqBy } from 'lodash'
import moment from 'moment'

interface sortedFieldsChats {
 last_message: { created_at: string }
}

const getters: GetterTree<ChatsState, RootState> = {
  modifiedChatsList: state => {
    const sortedChats = state.chatsList.sort((a: sortedFieldsChats, b: sortedFieldsChats) => (
      a.last_message.created_at > b.last_message.created_at)
      ? -1
      : ((a.last_message.created_at < b.last_message.created_at) ? 1 : 0))
    return uniqBy(sortedChats, 'id')
  },

  chatMessagesById: state => id => state.chatsMessages[id] || []
}

export default getters
