import { CatalogContent, ProductsState, Catalog, CurrentCategoryTab, CatalogCategory, CatalogChildCategory, Product } from '@/store/modules/catalog/types'

const state: ProductsState = {
  catalog: {} as Catalog,
  categoriesHierarchy: [],
  catalogCategories: [] as CatalogCategory[],
  catalogChildCategories: [] as CatalogChildCategory[],
  catalogChildCategoryProducts: [] as Product[],
  catalogs: [] as Catalog[],
  selectedCatalogCategory: {} as CatalogCategory,
  selectedCatalogChildCategory: {} as CatalogChildCategory,
  currentCategoryTab: {} as CurrentCategoryTab,
  editingCatalogCategory: null as CatalogCategory,
  editingCatalogChildCategory: null as CatalogChildCategory,
  catalogContent: {} as CatalogContent,
  catalogsForCopy: []
}

export default state
